var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.user.groups.includes(7))?_c('Card',{staticClass:"dashboard-card",attrs:{"variant_seventh":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"container-title-my-classes"},[_c('div',[_c('b-icon',{staticClass:"card-header-icon",attrs:{"icon":"journal-text"}}),_vm._v(" Mis Clases ")],1),(
            _vm.user.groups.includes(7) &&
            _vm.institution &&
            _vm.institution.kl_assessment
          )?_c('div',{staticClass:"noprint"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.redirectToStudentGradeView()}}},[_vm._v(" Mis Notas ")])],1):_vm._e()])]},proxy:true},{key:"content",fn:function(){return [(_vm.institution && _vm.institution.kl_assessment)?[(_vm.user.groups.includes(7) && _vm.sections_student.length > 0)?[_c('b-table-simple',{staticClass:"border-table",attrs:{"bordered":"","borderless":"","small":"","hover":"","sticky-header":"400px"}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"primary-cell-my-classes",staticStyle:{"width":"30%"}},[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" )))]),_c('b-th',{staticClass:"primary-cell-my-classes",staticStyle:{"width":"15%"}},[_vm._v(_vm._s(_vm.$getVisibleNames("mesh.campus", false, "Sede")))]),_c('b-th',{staticClass:"primary-cell-my-classes",staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("teaching.section", false, "Sección"))+" ")]),_c('b-th',{staticClass:"primary-cell-my-classes",staticStyle:{"width":"15%"}},[_vm._v(_vm._s(_vm.$getVisibleNames("mesh.period", false, "Periodo")))]),_c('b-th',{staticClass:"primary-cell-my-classes",staticStyle:{"width":"10%"}},[_vm._v(" Docentes ")])],1)],1),_c('b-tbody',[_vm._l((_vm.sections_student),function(section){return _vm._l((section.sections),function(sectionMatterStudent){return _c('b-tr',{key:'period' +
                    section.period_id +
                    'section' +
                    sectionMatterStudent.id},[_c('b-td',{staticClass:"secondary-cell-my-classes"},[_c('div',{staticClass:"d-flex container-matter-my-classes"},[(
                          sectionMatterStudent.egress_profile_matter_id !=
                          null
                        )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                          `${
                            _vm.institution &&
                            _vm.institution.internal_use_id == 'duoc_uc'
                              ? 'Ir al PA Estudiante'
                              : 'Ir al ' +
                                _vm.$getVisibleNames(
                                  'manual.matter_program',
                                  false,
                                  'Programa de Asignatura'
                                )
                          }`
                        ),expression:"\n                          `${\n                            institution &&\n                            institution.internal_use_id == 'duoc_uc'\n                              ? 'Ir al PA Estudiante'\n                              : 'Ir al ' +\n                                $getVisibleNames(\n                                  'manual.matter_program',\n                                  false,\n                                  'Programa de Asignatura'\n                                )\n                          }`\n                        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"mr-0 div-href-test",on:{"click":function($event){return _vm.redirectToPA(
                            sectionMatterStudent.egress_profile_matter_id
                          )}}},[_vm._v(" "+_vm._s(sectionMatterStudent.matter_code)+" - "+_vm._s(sectionMatterStudent.matter_name)+" ")]):_c('div',{staticClass:"mr-0"},[_vm._v(" "+_vm._s(sectionMatterStudent.matter_code)+" - "+_vm._s(sectionMatterStudent.matter_name)+" ")])])]),_c('b-td',{staticClass:"secondary-cell-my-classes text-left"},[_vm._v(" "+_vm._s(sectionMatterStudent.campus_name)+" ")]),_c('b-td',{staticClass:"secondary-cell-my-classes text-left"},[_vm._v(" "+_vm._s(sectionMatterStudent.name)+" ")]),_c('b-td',{staticClass:"secondary-cell-my-classes text-left"},[_vm._v(" "+_vm._s(section.period_name)+" ")]),_c('b-td',{staticClass:"secondary-cell-my-classes text-left"},[_vm._v(" "+_vm._s([ ...new Set( section.sections.map((x) => x.professors).flat() ), ].join(", "))+" ")])],1)})})],2)],1)]:_vm._e(),(_vm.user.groups.includes(7) && _vm.sections_student.length == 0)?[_c('div',{staticClass:"text-left"},[_vm._v(" No hay clases cursadas en este "+_vm._s(_vm.$getVisibleNames("mesh.period", false, "Periodo"))+" ")])]:_vm._e()]:[_c('div',{staticClass:"text-left"},[_c('span',[_vm._v("Este módulo no está habilitado para su institución")])])]]},proxy:true}],null,false,3936329641)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }