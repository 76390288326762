<template>
  <div>
    <!-- -------------------------Evaluaciones "Mis Clases" Estudiante------------------------- -->
    <Card
      v-if="user.groups.includes(7)"
      class="dashboard-card"
      :variant_seventh="true"
    >
      <template v-slot:title>
        <div class="container-title-my-classes">
          <div>
            <b-icon class="card-header-icon" icon="journal-text"></b-icon>
            Mis Clases
          </div>
          <div
            v-if="
              user.groups.includes(7) &&
              institution &&
              institution.kl_assessment
            "
            class="noprint"
          >
            <b-button
              variant="primary"
              size="sm"
              class="mr-1"
              @click="redirectToStudentGradeView()"
            >
              Mis Notas
            </b-button>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <template v-if="institution && institution.kl_assessment">
          <template
            v-if="user.groups.includes(7) && sections_student.length > 0"
          >
            <b-table-simple
              bordered
              borderless
              small
              hover
              sticky-header="400px"
              class="border-table"
            >
              <b-thead>
                <b-tr>
                  <b-th class="primary-cell-my-classes" style="width: 30%">{{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      false,
                      "Asignatura"
                    )
                  }}</b-th>
                  <b-th class="primary-cell-my-classes" style="width: 15%">{{
                    $getVisibleNames("mesh.campus", false, "Sede")
                  }}</b-th>
                  <b-th class="primary-cell-my-classes" style="width: 15%">
                    {{ $getVisibleNames("teaching.section", false, "Sección") }}
                  </b-th>
                  <b-th class="primary-cell-my-classes" style="width: 15%">{{
                    $getVisibleNames("mesh.period", false, "Periodo")
                  }}</b-th>
                  <b-th class="primary-cell-my-classes" style="width: 10%">
                    Docentes
                  </b-th>
                </b-tr>
              </b-thead>

              <b-tbody>
                <template v-for="section in sections_student">
                  <b-tr
                    v-for="sectionMatterStudent in section.sections"
                    :key="
                      'period' +
                      section.period_id +
                      'section' +
                      sectionMatterStudent.id
                    "
                  >
                    <!-- Nombre de la Asignatura -->
                    <b-td class="secondary-cell-my-classes">
                      <div class="d-flex container-matter-my-classes">
                        <div
                          v-if="
                            sectionMatterStudent.egress_profile_matter_id !=
                            null
                          "
                          class="mr-0 div-href-test"
                          v-b-tooltip.v-secondary.top.noninteractive="
                            `${
                              institution &&
                              institution.internal_use_id == 'duoc_uc'
                                ? 'Ir al PA Estudiante'
                                : 'Ir al ' +
                                  $getVisibleNames(
                                    'manual.matter_program',
                                    false,
                                    'Programa de Asignatura'
                                  )
                            }`
                          "
                          @click="
                            redirectToPA(
                              sectionMatterStudent.egress_profile_matter_id
                            )
                          "
                        >
                          {{ sectionMatterStudent.matter_code }} -
                          {{ sectionMatterStudent.matter_name }}
                        </div>
                        <div v-else class="mr-0">
                          {{ sectionMatterStudent.matter_code }} -
                          {{ sectionMatterStudent.matter_name }}
                        </div>
                        <!-- Botón 3 puntos que abre popover -->
                        <!-- <b-button
                            variant="none"
                            size="sm"
                            :id="`popover-my-classes-student-${sectionMatterStudent.id}`"
                            class="btn-three-dots"
                          >
                            <b-icon icon="three-dots" class="icon-three-dots">
                            </b-icon>
                          </b-button>
                          <b-popover
                            :target="`popover-my-classes-student-${sectionMatterStudent.id}`"
                            triggers="hover"
                          >
                            <b-button
                              variant="primary"
                              class="btn-popover-my-classes"
                              @click="redirectToStudentGradeView()"
                            >
                              Revisar Notas de la
                              {{
                                $getVisibleNames(
                                  "mesh.egressprofilematter",
                                  false,
                                  "Asignatura"
                                )
                              }}</b-button
                            >
                          </b-popover> -->
                      </div>
                    </b-td>
                    <!-- Nombre del Campus -->
                    <b-td class="secondary-cell-my-classes text-left">
                      {{ sectionMatterStudent.campus_name }}
                    </b-td>
                    <!-- Nombre de la Seccion -->
                    <b-td class="secondary-cell-my-classes text-left">
                      {{ sectionMatterStudent.name }}
                    </b-td>
                    <!-- Nombre del Periodo -->
                    <b-td class="secondary-cell-my-classes text-left">
                      {{ section.period_name }}
                    </b-td>
                    <b-td class="secondary-cell-my-classes text-left">
                      {{
                        [
                          ...new Set(
                            section.sections.map((x) => x.professors).flat()
                          ),
                        ].join(", ")
                      }}
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </template>
          <template
            v-if="user.groups.includes(7) && sections_student.length == 0"
          >
            <div class="text-left">
              No hay clases cursadas en este
              {{ $getVisibleNames("mesh.period", false, "Periodo") }}
            </div>
          </template>
        </template>
        <template v-else>
          <div class="text-left">
            <span>Este módulo no está habilitado para su institución</span>
          </div>
        </template>
      </template>
    </Card>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "CurrentSectionStudent",
  components: {
    Card: () => import("@/components/users/Card"),
  },
  data() {
    return {
      sections_student: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
    }),
  },
  methods: {
    redirectToPA(egress_profile_matter_id) {
      if (this.institution && this.institution.internal_use_id == "duoc_uc")
        this.$router.push({
          name: "LearningSuitcaseStudentView",
          params: {
            egress_profile_matter_id: egress_profile_matter_id,
          },
        });
      else {
        this.$router.push({
          name: "MatterProgramView",
          params: {
            matter_id: egress_profile_matter_id,
          },
        });
      }
    },
    redirectToStudentGradeView() {
      this.$router.push({
        name: "StudentGradeView",
      });
    },
    getSectionStudent() {
      if (
        this.user &&
        this.user.groups.includes(7) &&
        this.sections_student.length == 0 &&
        this.institution &&
        this.institution.kl_assessment
      ) {
        this.$restful
          .Get("/evaluations2/dashboard_student_current_sections/")
          .then((response) => {
            this.sections_student = response;
          });
      }
    },
  },
  mounted() {
    this.getSectionStudent();
  },
  watch: {
    user() {
      this.getSectionStudent();
    },
    institution() {
      this.getSectionStudent();
    },
  },
  created() {
    this.$hasPermissions();
  },
};
</script>

<style scoped>
.container-title-my-classes {
  display: flex;
  justify-content: space-between;
}
.dashboard-card {
  display: block;
  border-radius: 7px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 1px 8px -1px #9d9d9d;
  /* box-shadow: 0px 1px 5px 0px #9d9d9d; */
  transition: all 0.5s ease-out;
}
.card-header-icon {
  margin-top: 2px;
  margin-right: 0.1em;
}
.border-table {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.primary-cell-my-classes {
  text-align: left;
  vertical-align: middle;
  background: #eed9b8 !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.secondary-cell-my-classes {
  text-align: center;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.div-href-test {
  color: #007bff;
}
.div-href-test:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
.container-btn-three-dots {
  max-width: 250px;
}
.btn-three-dots {
  border-radius: 50%;
  padding: 2.5px 3px;
}
.btn-popover-my-classes {
  padding: 0.1rem 0.3rem;
  font-size: 11pt;
  border-radius: 4px;
}
@media print {
  .secondary-cell-my-classes {
    background-color: transparent !important;
  }
}
</style>